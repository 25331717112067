import React, { useState, useEffect, useRef } from 'react'
import Image from 'gatsby-image'

const SlideShow = ({ data }) => {
  const dataSlide = data.l

  const [counter, setCounter] = useState(0)
  const r = useRef(null)
  r.current = { counter, setCounter }

  const nbr = dataSlide.length - 1
  const timer = 3000

  useEffect(() => {
    const id = setInterval(() => {
      if (counter < nbr) {
        r.current.setCounter(r.current.counter + 1)
      } else {
        r.current.setCounter((r.current.counter = 0))
      }
    }, timer)
    return () => {
      clearInterval(id)
    }
  }, [counter, nbr])

  return (
    <>
      {dataSlide.map((it, id) => {
        return (
          <Image
            key={id}
            fixed={it.fixed}
            alt={it.title}
            className={`logoMark ${
              r.current.counter === id ? 'active' : 'inactive'
            }`}
          />
        )
      })}
    </>
  )
}

export default SlideShow
