import React from 'react'
import { graphql } from 'gatsby'

//components
import RichContent from '../components/content/RichContent'
import Marque from '../components/zone/Marque'

const TemplateServiceSub = ({ data }) => {
  console.log(data.c.marque)
  return (
    <div className='serviceSub'>
      <div className='container'>
        <RichContent myRichContent={data.c.content} />
      </div>
      {data.c.marque ? <Marque /> : ''}
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    c: contentfulServiceSub(id: { eq: $id }) {
      title
      content {
        json
      }
      marque
    }
  }
`
export default TemplateServiceSub
