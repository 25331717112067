import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Image from 'gatsby-image'
//component
import SlideShow from '../block/SlideShow'
//scss
import '../../scss/zone/marque.scss'
//data
const getData = graphql`
  {
    c: allContentfulMarque(filter: {}, sort: { fields: order, order: ASC }) {
      nodes {
        title
        slug
        l: logoMarque {
          title
          fixed {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
    d: contentfulConfig {
      marqueTitle
    }
  }
`
const Marque = () => {
  const data = useStaticQuery(getData)

  return (
    <div className='zone marque'>
      <div className='container'>
        <h2>{data.d.marqueTitle}</h2>
        <div className='flex'>
          {data.c.nodes.map((item, index) => {
            return (
              <div key={index} className='marqueElm'>
                <div className='img'>
                  {item.l.length > 1 ? (
                    <SlideShow data={item} />
                  ) : (
                    item.l.map((it, id) => (
                      <Image
                        key={id}
                        fixed={it.fixed}
                        alt={it.title}
                        className={`logoMark active`}
                      />
                    ))
                  )}
                </div>
                <div className='title'>{item.title}</div>
                <Link to={`/${item.slug}`}>voir</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Marque
